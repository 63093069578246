import React from 'react';
import ReactPlayer from 'react-player/youtube'
import './Styles.css';

function ResponsivePlayer () {
  return (
<div className='player-wrapper'>
        <ReactPlayer
          className='react-player'
          url='https://www.youtube.com/watch?v=X6wTeni6SL0&loop=1'
          width='100%'
          height='100%'
          pip={false}
          muted={true}
          playing={true}
          loop={true}
        />
      </div>
  );
}

export default ResponsivePlayer ;