import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Component } from 'react';

export default class SocialHeader extends Component {
  render() {
    return (
        <div>
        <div style={{alignItems: "left"}}>Follow Gamma Games</div>
      <div style={{alignItems: "left"}}>
        <FacebookIcon fontSize="large" onClick={() => window.open('https://m.facebook.com/gammagames1/?_rdr', '_blank')} />
        <InstagramIcon fontSize="large" onClick={() => window.open('https://instagram.com/gamma_games?igshid=YmMyMTA2M2Y=', '_blank')}/>
        <YouTubeIcon fontSize="large" onClick={() => window.open('https://www.youtube.com/c/GammaGamesIndia', '_blank')}/>
      </div>
      </div>
    )
  }
}
