import { Auth } from '@aws-amplify/auth';
import { CognitoUserAmplify } from "@aws-amplify/ui";
import { Button, CircularProgress, Container, Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Component } from 'react';
import { Profile } from '../../API';
import AchievementScreen from './AchievementScreen';
import CoachScreen from './CoachScreen';
import { ProflieService } from './ProfileService';

export default class ViewProfile extends Component<{ user: CognitoUserAmplify | any }, { userProfile: Profile | undefined, loadCompleted: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { userProfile: undefined, loadCompleted: false }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

  componentDidMount(): void {
    if (this.props.user != null) {
      ProflieService.getProfilesByEmail(this.props.user.attributes.email).then((response: any) => {
        this.setState({ userProfile: response.data.listProfiles.items[0], loadCompleted: true })
      })
    }
  }


  handleSubmit(evt: any) {
    evt.preventDefault();
    if (this.state.userProfile) {
      ProflieService.updateProfile(this.state.userProfile);
    }
  }
  handleChange(evt: any) {
    evt.preventDefault();
    const value = evt.target.value;
    const name = evt.target.name;
    let tempProfile = this.state.userProfile;
    if (tempProfile) {
      this.setState({ userProfile: { ...tempProfile, [name]: value } })
    }

  }

  render() {
    return (
      <div >
        <Container maxWidth="xl" >
          <Paper elevation={3}>
            <Typography sx={{ fontWeight: 'bold' }} align="left" variant="h2" className='event'>
              Basic Details
            </Typography>
            <div className='decorative_bar' />
            {!this.state.loadCompleted && <CircularProgress />}
            {this.state.loadCompleted && this.getform()}
            <div className='parent'>
            <CoachScreen userProfile={this.state.userProfile} />
            <br/>
            <AchievementScreen userProfile={this.state.userProfile}/>
            <br/>
            </div>
            <Button type="submit" variant="contained" color="primary" onClick={() => Auth.signOut()}>Log out</Button >
          </Paper>
        </Container>
      </div>

    )
  }
  getform() {
    return (<div className='basic_detail'>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': {
            m: 1, width: '25ch'
          }, width: '25%', pl: '5%', pt: '2%', pb: '2%', minWidth: '50%', borderRadius: 2,
        }

        }

        noValidate
        autoComplete="off"
        onSubmit={this.handleSubmit}
      >
        <div>
          <div>
            <TextField
              required
              id="outlined-required"
              label="Name"
              name="name"
              value={this.state.userProfile?.name || ''}
              onChange={this.handleChange}
            />
          </div>
          <div>
            <TextField
              required
              id="outlined-required"
              label="Mobile"
              name="mobile"
              value={this.state.userProfile?.mobile || ''}
              onChange={this.handleChange}
            />
          </div>
          <div>
            <TextField
              required
              id="outlined-required"
              label="Academy"
              name="academy"
              value={this.state.userProfile?.academy || ''}
              onChange={this.handleChange}
            />
          </div>
          <div>
            <TextField
              id="outlined-required"
              label="Email"
              name="email"
              value={this.state.userProfile?.email || ''}
              onChange={this.handleChange}
            />
          </div>
          <div>
            <TextField
              required
              id="outlined-required"
              label="Instagram"
              name="instagram"
              value={this.state.userProfile?.instagram || ''}
              onChange={this.handleChange}
            />
          </div>
          <div>
            <TextField
              required
              id="outlined-required"
              label="Youtube"
              name="youtube"
              value={this.state.userProfile?.youtube || ''}
              onChange={this.handleChange}
            />
          </div>
          <div>
            <TextField
              required
              id="outlined-required"
              label="Facebook"
              name="facebook"
              value={this.state.userProfile?.facebook || ''}
              onChange={this.handleChange}
            />
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            Update
          </Button>
        </div>

      </Box>

    </div>)
  }
}
