
import { format } from 'date-fns';
export class DateUtils{

   public static getDate(date: string | null | undefined): string {
        if (date === undefined || date === null) {
            return format(new Date(), 'do MMM YYY');
        }
        return format(new Date(date), 'do MMM YYY');
    }
    
}