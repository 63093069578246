import { CognitoUserAmplify } from "@aws-amplify/ui";
import { Auth } from "@aws-amplify/auth";

export class UserUtils {
    public static async getUser() {
        return await Auth.currentAuthenticatedUser();
    }
    public static isLoggedIn(user:CognitoUserAmplify):boolean{
        if(user==null){
            return false;
        }
        return true;
    }


    public static  isAdminUser(user:CognitoUserAmplify):boolean {
        if(user==null){
            return false;
        }
      const group=  user.getSignInUserSession()?.getAccessToken().payload["cognito:groups"];
      if(group.includes('AdminGroup')){
        return true;
      }
       return false;

    }
    public static isMobile():boolean{
      return  window.innerWidth <= 500
    }
}