import { Route, Routes } from "react-router-dom";
import './App.css';
import ViewEvent from './components/events/viewEvent/ViewEvent';
import GammaHeader from './components/GammaHeader';
import Home from './components/Home';
import Logout from './components/menuPages/Logout';
import Profile from './components/profile/Profile';

import { Hub } from "@aws-amplify/core";
import React, { Component, Suspense } from 'react';
import UpcomingEvents from "./components/events/UpcomingEvents";
import Footer from "./components/Footer";
import { ProflieService } from "./components/profile/ProfileService";
import SocialHeader from "./components/SocialHeader";
import { UserUtils } from './components/UserUtils';
import { withRouter } from './components/WithRouter';
React.lazy(() => import("./components/events/AdminUpdateEvent"));
const PrivatePolicy = React.lazy(() => import("./components/PrivatePolicy"));
const CreateEvents = React.lazy(() => import("./components/events/CreateEvents"));
const Admin = React.lazy(() => import("./components/admin/Admin"));
const AthleteSearchScreen = React.lazy(() => import("./components/athletes/AthleteSearchScreen"));
const ViewAthlete = React.lazy(() => import("./components/athletes/ViewAthlete"));

class App extends Component<{ navigate: any }, { user: any | null }> {
  constructor(props: any) {
    super(props);

    this.state = { user: null };

  }
  componentDidMount(): void {
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          UserUtils.getUser().then((user) => {
            ProflieService.getProfilesByEmail(user.attributes.email).then((response)=>{
              if(response.data.listProfiles.items.length==0){
                ProflieService.createProfile({email:user.attributes.email,name:user.attributes.name});
              }
            }).catch(()=>{
            })
          })
          break;

      }
    });
    UserUtils.getUser().then((response) => {
      this.setState({ user: response })
    }).catch(()=>{
      this.setState({ user: null })
    })
  }
  componentWillUnmount(){
    Hub.remove("auth",()=>{})
  }
 
  render() {
    return (
      <div className="App">
        {<GammaHeader user={this.state.user} navigate={this.props.navigate} ></GammaHeader>}
        <SocialHeader/>
        <Suspense fallback={<div>Loading... </div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/event" element={<UpcomingEvents />} />
          <Route path="/privacy" element={<PrivatePolicy />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/profile" element={<Profile user={this.state.user} />} />
          <Route path="/admin" element={<Admin user={this.state.user} />} />
          <Route path="/createEvent" element={<CreateEvents user={this.state.user}/>} />
          <Route path="/viewEvent/:id" element={<ViewEvent />} />
          <Route path="/athelete/:id" element={<ViewAthlete />} />
          <Route path="/athelete-search" element={<AthleteSearchScreen />} />
        </Routes>
        </Suspense>
        <Footer/>
      </div>
    );
    
  }

}


export default withRouter(App);
