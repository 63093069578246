import ViewProfile from './ViewProfile';

import { Component } from 'react';
import { withAuthentication } from '../WithRouter';

class Profile extends Component<{user:any}> {
  render() {
    return (
      <div><ViewProfile user={this.props.user}  /></div>
    )
  }
}


export default withAuthentication(Profile)