import { Typography } from '@mui/material';
import { Component } from 'react';
import { Event } from '../../../API';
import { DateUtils } from '../../DateUtils';
import './../../../App.css';
export default class BasicForm extends Component<{ event: Event|null }>{
    render() {
        return this.getBasicForm();
    }
    private getBasicForm(): JSX.Element {
        if (!this.props.event) {
            return <div />;
        }
        return (

            <div className='event' >
                <div style={{ display: "flex" }}>
                    <Typography variant="h6" align="left" noWrap>{DateUtils.getDate(this.props.event.startDate)} &nbsp;&nbsp;</Typography>
                    <Typography variant="h6" align="right" noWrap>{this.props.event.place}</Typography>
                </div>
                <br />
                <Typography variant="h4" align="left" sx={{ fontWeight: 'bold' ,color: '#f5822a'}}>{this.props.event.name} </Typography>
                <div className='view_event_form'>
                    <br />
                    <Typography variant="h6" align="left">{this.props.event.description}</Typography>
                    <br />
                    <br />
                </div>

            </div>
        )
    }
}
