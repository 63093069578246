
import { CircularProgress, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns';
import { Component } from 'react';
import { Event } from '../../API';
import { DateUtils } from '../DateUtils';
import { UserUtils } from '../UserUtils';
import { withRouter } from '../WithRouter';
import { EventService } from './EventService';

class UpcomingEvents extends Component<{ navigate: any }, { upcomingEvents: Event[], loadCompleted: boolean }> {
  es = new EventService();
  constructor(props: any) {
    super(props);
    this.state = { upcomingEvents: [], loadCompleted: false }
    this.openEvent = this.openEvent.bind(this);


  }
  openEvent(id: string) {
    let url = "/viewEvent/" + id;
    this.props.navigate(url)


  }

  componentDidMount(): void {
    this.es.getUpcomingEvents().then((response) => {
      this.setState({ upcomingEvents: response.data.listEvents.items, loadCompleted: true })

    })
  }
  render() {
    return (
      <div >
        <Container maxWidth="xl" >

          <Typography sx={{ fontWeight: 'bold' }} align="left" variant="h2" className='event'>
            Events
          </Typography>
          <Paper elevation={3}>
            <div className='decorative_bar' />
            {!this.state.loadCompleted && <CircularProgress />}
            {this.state.loadCompleted && !UserUtils.isMobile() && this.getTable()}
            {this.state.loadCompleted && UserUtils.isMobile() && this.getCards()}
          </Paper>
        </Container>
      </div>
    )
  }
  getTable() {
    return (
      <div className='table'>

        <TableContainer component={Paper}>
          <Table aria-label="customized table" className='hover'>
            <TableBody>
              {this.state.upcomingEvents.map((row) => (
                <StyledTableRow key={row.name} onClick={() => this.openEvent(row.id)}>
                  <StyledTableCell sx={{ fontWeight: 'bold' }} align="left">{DateUtils.getDate(row.startDate)}</StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'bold', color: '#f5822a' }} align="left">{row.name} </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'bold' }} align="left">{row.place}</StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'bold' }} align="left">{row.description}</StyledTableCell>

                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }

  getCards() {
    return (
      <div>
        {this.state.upcomingEvents.map((row) => (
          this.getCardForMobile(row)
        ))}
      </div>

    );
  }
  getCardForMobile(row: Event) {
    return (
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} gutterBottom>
            {DateUtils.getDate(row.startDate)}
          </Typography>
          <Typography variant="h5" component="div" sx={{ color: '#f5822a' }}>
            {row.name}
          </Typography>
          <Typography >
            {row.place}
          </Typography>
          <Typography variant="body2">
            {row.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => this.openEvent(row.id)}>More </Button>
        </CardActions>
      </Card>
    );
  }

}
export default withRouter(UpcomingEvents);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
