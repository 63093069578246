import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import './Styles.css';

import { Component } from 'react';
import { UserUtils } from './UserUtils';

interface PageTabAndUrl {
  menuTitle: string,
  pageURL: string

}
const adminTab: PageTabAndUrl = {
  menuTitle: "Admin",
  pageURL: "/admin"
}

const pages: PageTabAndUrl[] = [{
  menuTitle: "Event",
  pageURL: "/event"
}, {
  menuTitle: "Athlete",
  pageURL: "/athelete-search"
}
];


export default class GammaHeader extends Component<{ user: any | null, navigate: any | null }, { anchorElNav: null | HTMLElement }> {
  constructor(props: any) {
    super(props);
    this.state = { anchorElNav: null };
    this.handleCloseNavMenu = this.handleCloseNavMenu.bind(this);
    this.handleOpenNavMenu = this.handleOpenNavMenu.bind(this);
    this.navigatetoProfile = this.navigatetoProfile.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

  }

  handleLogin() {
    this.props.navigate("/profile")
  }
  navigatetoProfile(pageUrl: string) {
    this.props.navigate(pageUrl)
  }

  handleOpenNavMenu(event: React.MouseEvent<HTMLElement>) {
    this.setState({ anchorElNav: event.currentTarget });
  }
  handleCloseNavMenu(pageUrl: string) {
    this.props.navigate(pageUrl)
    this.setState({ anchorElNav: null });
  };

  render() {
    {
      if (UserUtils.isAdminUser(this.props.user) && !pages.includes(adminTab)) {
        pages.push(adminTab);
      }
    }
    return (
      <AppBar position="static" >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: '#fff',
                textDecoration: 'none',
              }}
            >
              GAMMA
            </Typography>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: '#fa8072',
                textDecoration: 'none',
              }}
            >
              GAMES
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(this.state.anchorElNav)}
                onClose={() => this.setState({ anchorElNav: null })}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page: PageTabAndUrl) => (
                  <MenuItem key={page.menuTitle} onClick={() => this.handleCloseNavMenu(page.pageURL)}>
                    <Typography textAlign="center">{page.menuTitle}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.2rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              Gamma
            </Typography>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.2rem',
                color: '#fa8072',
                textDecoration: 'none',
              }}
            >
              Games
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page: PageTabAndUrl) => (
                <Button
                  key={page.menuTitle}
                  onClick={() => this.handleCloseNavMenu(page.pageURL)}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page.menuTitle}
                </Button>
              ))}
            </Box>
            {!UserUtils.isLoggedIn(this.props.user) && (<Button color="inherit" onClick={() => this.handleLogin()}>Login</Button >)}
            {UserUtils.isLoggedIn(this.props.user) && (<Button color="inherit" onClick={() => this.navigatetoProfile("/profile")}>Profile</Button>)}


          </Toolbar>
        </Container>
      </AppBar>
    );
  }
}

