import { Button, Card, CardContent, Dialog, DialogTitle, TextField, Typography } from '@mui/material';
import { Component } from 'react';
import { Achievement, CreateAchievementInput, Profile } from '../../API';
import { ProflieService } from './ProfileService';

export default class AchievementScreen extends Component<{ userProfile: Profile | undefined }, { open: boolean, achievement: CreateAchievementInput }> {
  constructor(props: any) {
    super(props);
    this.state = { open: false, achievement: { profileID: " " } }
    this.openPopup = this.openPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateAchievement = this.updateAchievement.bind(this);


  }

  openPopup(evt: any) {
    this.setState({ open: true });
  }
  closePopup(evt: any) {
    this.setState({ open: false });

  }
  updateAchievement(evt: any) {
    if (this.props.userProfile !== undefined) {
      const achievement= this.state.achievement;
      achievement.profileID=this.props.userProfile.id
      ProflieService.addAchievement(achievement);
    }
    this.setState({ open: false });
  }
  removeAchievement(achievement: Achievement) {
    ProflieService.removeAchievement(achievement)
  }
  handleChange(evt: any) {
    evt.preventDefault();
    const value = evt.target.value;
    const name = evt.target.name;
    let tempProfile = this.state.achievement;
    this.setState({ achievement: { ...tempProfile, [name]: value } })

  }
  render() {
    return (
      <div className='child'>
        <div className='decorative_bar' />
        {this.getUpdateCoachDialog()}
        {this.showCoaches()}
      </div>
    )
  }
  private showCoaches() {
    if (this.props.userProfile === undefined) {
      return <div />
    }
    const achievement = this.props.userProfile.Achievements
    if (achievement === undefined || achievement?.items === undefined) {
      return <div />
    }
    let achievementArray = []
    for (let index = 0; index < achievement.items.length; index++) {
      const achievementTemp = achievement.items[index];
      if (achievementTemp != null) {
        achievementArray.push(<div className='athlete_box'>
          <Card variant="outlined"  sx={{ width: 200,height:120 }} >
            <CardContent>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                {achievementTemp?.title}
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                {achievementTemp?.year}
              </Typography>
            </CardContent>
            <Button size="small" onClick={() => { this.removeAchievement(achievementTemp) }}>Remove </Button>
          </Card>

        </div>)
      }
    }
    return achievementArray;

  }

  private getUpdateCoachDialog() {
    return (
      <div>
        <br/>
        <Button size="small" variant="contained" color="primary" onClick={this.openPopup}>Add Achievement</Button>
        <Dialog onClose={this.closePopup} open={this.state.open} sx={{
          '& .MuiTextField-root': {
            m: 1, width: '200px'
          },  pl: '5%', pt: '2%', pb: '2%', borderRadius: 2,
        }

        }>
          <DialogTitle>Details</DialogTitle>

          <div>
            <div>
              <TextField
                required
                value={this.state.achievement.title}
                id="outlined-required"
                label="Title"
                name="title"
                onChange={this.handleChange}
              />
            </div>
            <div>
              <TextField
                required
                value={this.state.achievement.year}
                id="outlined-required"
                label="Year"
                name="year"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <Button size="small" onClick={this.updateAchievement}>Done </Button>
          <Button size="small" onClick={this.closePopup}>Cancel</Button>
        </Dialog>
      </div>
    )
  }
}
