import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { Component } from 'react';
import { Event } from '../../../API';
import { withAuthentication } from '../../WithRouter';
import { EventService } from '../EventService';
import { MatchService } from '../match/MatchService';
import './../../../App.css';
import BasicForm from './BasicForm';
import ViewCategory from './ViewCategory';


class ViewEvent extends Component<{ params: string }, { event: Event | null, loadCompleted: boolean }> {

    readOnly: boolean = true;
    es = new EventService();
    ms = new MatchService();
    constructor(props: any) {
        super(props);
        this.state = { event: null, loadCompleted: false }
    }

    componentDidMount(): void {
        this.es.getEventById(this.props.params).then((response) => {
            this.setState({ event: response.data.getEvent ,loadCompleted:true})
        })
    }

    render() {
        if (!this.state.event) {
            return <div />;
        }
        return (
            <div >
                {!this.state.loadCompleted && <CircularProgress />}
                {this.state.loadCompleted &&
                    <Container maxWidth="xl" >
                        <Paper elevation={3}>
                            <BasicForm event={this.state.event} />
                            <ViewCategory event={this.state.event} />
                        </Paper>
                    </Container>
                }

            </div>

        )
    }
}
export default withAuthentication(ViewEvent);
