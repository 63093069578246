import { Typography } from '@mui/material';
import { Component } from 'react';
import { Event } from '../../../API';
import AlertDialog from '../../genericComp/AlertDialog';
import { ProflieService } from '../../profile/ProfileService';
import { UserUtils } from '../../UserUtils';
import CatageoryComponent from '../category/CategoryComponent';
import { ParticipantService } from '../category/ParticipantService';
import './../../../App.css';
import ViewMatchDesktop from './ViewMatchDesktop';
import ViewMatchMobile from './ViewMatchMobile';

export default class ViewCategory extends Component<{ event: Event | null }, { selectedCategoryId: string | null,selectedCategoryName:string|null|undefined }> {
    constructor(props: any) {
        super(props);
        this.state = { selectedCategoryId: this.getSelectedId(),selectedCategoryName:this.getSelectedCategoryName() }
        this.toggleState = this.toggleState.bind(this);
        this.participate = this.participate.bind(this);
    }
    private getSelectedId(): string | null {
        const len = this.props.event?.Categories?.items.length
        if (len !== undefined && len !== null && len > 0 && this.props.event?.Categories?.items[0] != undefined) {
            return this.props.event?.Categories?.items[0].id;
        }
        return null;
    }
    private getSelectedCategoryName(): string | null|undefined {
        const len = this.props.event?.Categories?.items.length
        if (len != undefined && len != null && len > 0 && this.props.event?.Categories?.items[0] != undefined) {
            return this.props.event?.Categories?.items[0].name;
        }
        return null;
    }
    render() {
        return (
            <div>
                {this.getCategory()}
                {!UserUtils.isMobile() && <ViewMatchDesktop event={this.props.event} selectedCategory={this.state.selectedCategoryId} />}
                {UserUtils.isMobile() && <ViewMatchMobile event={this.props.event} selectedCategory={this.state.selectedCategoryId} />}
            </div>
        )
    }
    private participate(): void {
        if (this.state.selectedCategoryId != null) {
            const selId = this.state.selectedCategoryId
            UserUtils.getUser().then((user) => {
                ProflieService.getProfilesByEmail(user.attributes.email).then((response) => {
                    const id = response.data.listProfiles.items[0].id;
                    ParticipantService.participate({ categoryID: selId, participantsProfileId: id })
                }).catch(() => {
                })
            })
        }
    }
    private toggleState(id: string,name:string|null|undefined) {
        this.setState({ selectedCategoryId: id,selectedCategoryName:name })
    }
    private getCategory(): JSX.Element {
        if (!this.props.event) {
            return <div />;
        }


        if (!this.props.event.Categories || this.props.event.Categories.items == undefined) {
            return <div />;
        }
        const cats = [];
        for (let index = 0; index < this.props.event.Categories.items.length; index++) {
            const cat = this.props.event.Categories.items[index];
            cats.push(cat);
        }


        return (
            <div className='event_category'>
                <Typography sx={{ fontWeight: 'bold' }} align="left" variant="h3" className='event'>
                    Weight Categeory
                </Typography>
                <div className='decorative_bar' />
                <br />
                <div style={{ flexDirection: "row", justifyContent: "flex-start" }}>
                    {cats.map((row) => (
                        <CatageoryComponent category={row} toggleState={this.toggleState} selectedId={this.state.selectedCategoryId}></CatageoryComponent>
                    ))}
                </div>
                <br />
                <br />
                <AlertDialog buttonDisplay={"Participate"} displayMsg={" Participate in " +this.state.selectedCategoryName} sucessCallback={()=>this.participate() }/>
                <br />
                <br />
            </div>
        )
    }
}
