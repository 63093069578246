import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Component } from 'react';

export default class AlertDialog extends Component<{ buttonDisplay:String,displayMsg: String, sucessCallback: () => void }, { open: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = { open: false }
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(): void {
        this.setState({ open: false })
        this.props.sucessCallback();
    }
    render() {
        return (
            <div>
                <Button variant="outlined" onClick={() => this.setState({ open: true })}>
                    {this.props.buttonDisplay}
                </Button>
                <Dialog
                    open={this.state.open}
                    onClose={() => this.setState({ open: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {this.props.displayMsg}
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => this.setState({ open: false })}>Cancel</Button>
                        <Button onClick={this.handleClick} autoFocus>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
