import { API } from '@aws-amplify/api';
import { graphqlOperation } from 'aws-amplify';
import { Achievement, Coach, CreateAchievementInput, CreateCoachInput, CreateProfileInput, Profile, UpdateProfileInput } from '../../API';
import { createAchievement, createCoach, createProfile, deleteAchievement, deleteCoach, updateProfile } from '../../graphql/mutations';
import { listProfiles } from '../../graphql/queries';
import { findProfile, getProfileView } from '../../graphql/queriesV2';

export class ProflieService {

    public static async getProfilesByEmail(userEmail: string): Promise<any> {
        return await API.graphql({ query: listProfiles, variables: { filter: { email: { eq: userEmail} } }, authMode: 'API_KEY' });
    }
    public static async getProfileById(id: string): Promise<any> {
        // return await API.graphql({ query: getProfileView,variables: { filter: { id: { eq: id} } }, authMode: 'API_KEY' });
        return await API.graphql(graphqlOperation(getProfileView,id));
    }
    public static async findAthelete(searhString: string): Promise<any> {
        return await API.graphql({ query: findProfile, variables: { filter: { searchTag: { beginsWith: searhString} },limit:50 }, authMode: 'API_KEY' });
    }

    public static async createProfile(profile: CreateProfileInput) {
        const searchTag=profile.name?.toLowerCase();
        profile.searchTag=searchTag;
        await API.graphql({ query: createProfile, variables: { input: profile }, authMode: 'API_KEY' });
    }

    public static async updateProfile(profile: Profile) {
        const upProfile = ProflieService.createUpdateProfile(profile);
        
        await API.graphql({ query: updateProfile, variables: { input: upProfile }, authMode: 'API_KEY' });

    }
    public static async addCoach(coach: CreateCoachInput) {
        await API.graphql({ query: createCoach, variables: { input: coach }, authMode: 'API_KEY' });

    }
    public static async removeCoach(coach:Coach) {
        await API.graphql({ query: deleteCoach, variables: { input: {id:coach.id} }, authMode: 'API_KEY' });

    }
    public static async addAchievement(achievement:CreateAchievementInput) {
        await API.graphql({ query: createAchievement, variables: { input: achievement }, authMode: 'API_KEY' });

    }
    public static async removeAchievement(achievement:Achievement) {
        await API.graphql({ query: deleteAchievement, variables: { input: {id:achievement.id}}, authMode: 'API_KEY' });

    }
    private static createUpdateProfile(profile:Profile):UpdateProfileInput{
      
        const upProfile: UpdateProfileInput =
        {
            id: profile.id, 
            academy: (profile.academy)?profile.academy:" ", 
            email: profile.email, 
            mobile: (profile.mobile)?profile.mobile:" ", 
            name: profile.name,
            instagram: (profile.instagram)?profile.instagram:" ",
            youtube: (profile.youtube)?profile.youtube:" ",
            facebook:(profile.facebook)?profile.facebook:" ",
            searchTag:profile.name.toLowerCase()
        }
        return upProfile;
    }

}


