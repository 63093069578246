import { API, graphqlOperation } from '@aws-amplify/api';
import { CreateCategoryInput, CreateEventInput, CreateParticipantsInput, UpdateEventInput } from '../../API';
import { createCategory, createEvent, createParticipants, updateEvent } from '../../graphql/mutations';
import { getCategory, getEvent, listEvents } from '../../graphql/queries';
import { getEventAdmin } from '../../graphql/queriesV2';


export class EventService {
 

    public async getUpcomingEvents() :Promise<any> {
        return await API.graphql(graphqlOperation(listEvents));
        // await API.graphql({query:listEvents, authMode: 'AMAZON_COGNITO_USER_POOLS'});
    }

    public async getPastEvents():Promise<any> {
        return await API.graphql(graphqlOperation(listEvents));
    }

    public  async getAdminEventById(id:string):Promise<any> {
        return await API.graphql(graphqlOperation(getEventAdmin,id));
    }
    public  async getEventById(id:string):Promise<any> {
        return await API.graphql(graphqlOperation(getEvent,id));
    }
    public async createEvent(event:CreateEventInput){
        await API.graphql({query:createEvent,variables:{input:event}, authMode: 'AMAZON_COGNITO_USER_POOLS'});

    }
    public async updateEvent(event:UpdateEventInput){
        return await API.graphql(graphqlOperation(updateEvent,{input:event}));

    }
    public async paritcipate(categoryId:string,userId:string){
        const partcipants:CreateParticipantsInput={participantsProfileId:userId ,categoryID:categoryId};
        await API.graphql({query:createParticipants,variables:{input:partcipants}, authMode: 'AMAZON_COGNITO_USER_POOLS'});

    }
    public async getCategory(id:any):Promise<any>{
        return await API.graphql(graphqlOperation(getCategory,id));

    }
    public async createCategory(category:CreateCategoryInput){
        await API.graphql({query:createCategory,variables:{input:category}, authMode: 'AMAZON_COGNITO_USER_POOLS'});

    }
}