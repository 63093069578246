/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAchievement = /* GraphQL */ `
  mutation CreateAchievement(
    $input: CreateAchievementInput!
    $condition: ModelAchievementConditionInput
  ) {
    createAchievement(input: $input, condition: $condition) {
      id
      title
      year
      profileID
      createdAt
      updatedAt
    }
  }
`;
export const updateAchievement = /* GraphQL */ `
  mutation UpdateAchievement(
    $input: UpdateAchievementInput!
    $condition: ModelAchievementConditionInput
  ) {
    updateAchievement(input: $input, condition: $condition) {
      id
      title
      year
      profileID
      createdAt
      updatedAt
    }
  }
`;
export const deleteAchievement = /* GraphQL */ `
  mutation DeleteAchievement(
    $input: DeleteAchievementInput!
    $condition: ModelAchievementConditionInput
  ) {
    deleteAchievement(input: $input, condition: $condition) {
      id
      title
      year
      profileID
      createdAt
      updatedAt
    }
  }
`;
export const createCoach = /* GraphQL */ `
  mutation CreateCoach(
    $input: CreateCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    createCoach(input: $input, condition: $condition) {
      id
      coachName
      from
      profileID
      createdAt
      updatedAt
    }
  }
`;
export const updateCoach = /* GraphQL */ `
  mutation UpdateCoach(
    $input: UpdateCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    updateCoach(input: $input, condition: $condition) {
      id
      coachName
      from
      profileID
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoach = /* GraphQL */ `
  mutation DeleteCoach(
    $input: DeleteCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    deleteCoach(input: $input, condition: $condition) {
      id
      coachName
      from
      profileID
      createdAt
      updatedAt
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      age
      academy
      mobile
      email
      name
      video
      instagram
      youtube
      Coaches {
        nextToken
      }
      Achievements {
        nextToken
      }
      searchTag
      facebook
      createdAt
      updatedAt
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      age
      academy
      mobile
      email
      name
      video
      instagram
      youtube
      Coaches {
        nextToken
      }
      Achievements {
        nextToken
      }
      searchTag
      facebook
      createdAt
      updatedAt
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      age
      academy
      mobile
      email
      name
      video
      instagram
      youtube
      Coaches {
        nextToken
      }
      Achievements {
        nextToken
      }
      searchTag
      facebook
      createdAt
      updatedAt
    }
  }
`;
export const createParticipants = /* GraphQL */ `
  mutation CreateParticipants(
    $input: CreateParticipantsInput!
    $condition: ModelParticipantsConditionInput
  ) {
    createParticipants(input: $input, condition: $condition) {
      id
      Profile {
        id
        age
        academy
        mobile
        email
        name
        video
        instagram
        youtube
        searchTag
        facebook
        createdAt
        updatedAt
      }
      categoryID
      createdAt
      updatedAt
      participantsProfileId
    }
  }
`;
export const updateParticipants = /* GraphQL */ `
  mutation UpdateParticipants(
    $input: UpdateParticipantsInput!
    $condition: ModelParticipantsConditionInput
  ) {
    updateParticipants(input: $input, condition: $condition) {
      id
      Profile {
        id
        age
        academy
        mobile
        email
        name
        video
        instagram
        youtube
        searchTag
        facebook
        createdAt
        updatedAt
      }
      categoryID
      createdAt
      updatedAt
      participantsProfileId
    }
  }
`;
export const deleteParticipants = /* GraphQL */ `
  mutation DeleteParticipants(
    $input: DeleteParticipantsInput!
    $condition: ModelParticipantsConditionInput
  ) {
    deleteParticipants(input: $input, condition: $condition) {
      id
      Profile {
        id
        age
        academy
        mobile
        email
        name
        video
        instagram
        youtube
        searchTag
        facebook
        createdAt
        updatedAt
      }
      categoryID
      createdAt
      updatedAt
      participantsProfileId
    }
  }
`;
export const createMatch = /* GraphQL */ `
  mutation CreateMatch(
    $input: CreateMatchInput!
    $condition: ModelMatchConditionInput
  ) {
    createMatch(input: $input, condition: $condition) {
      id
      title
      round
      winner
      score
      Player1 {
        id
        age
        academy
        mobile
        email
        name
        video
        instagram
        youtube
        searchTag
        facebook
        createdAt
        updatedAt
      }
      Player2 {
        id
        age
        academy
        mobile
        email
        name
        video
        instagram
        youtube
        searchTag
        facebook
        createdAt
        updatedAt
      }
      categoryID
      url
      createdAt
      updatedAt
      matchPlayer1Id
      matchPlayer2Id
    }
  }
`;
export const updateMatch = /* GraphQL */ `
  mutation UpdateMatch(
    $input: UpdateMatchInput!
    $condition: ModelMatchConditionInput
  ) {
    updateMatch(input: $input, condition: $condition) {
      id
      title
      round
      winner
      score
      Player1 {
        id
        age
        academy
        mobile
        email
        name
        video
        instagram
        youtube
        searchTag
        facebook
        createdAt
        updatedAt
      }
      Player2 {
        id
        age
        academy
        mobile
        email
        name
        video
        instagram
        youtube
        searchTag
        facebook
        createdAt
        updatedAt
      }
      categoryID
      url
      createdAt
      updatedAt
      matchPlayer1Id
      matchPlayer2Id
    }
  }
`;
export const deleteMatch = /* GraphQL */ `
  mutation DeleteMatch(
    $input: DeleteMatchInput!
    $condition: ModelMatchConditionInput
  ) {
    deleteMatch(input: $input, condition: $condition) {
      id
      title
      round
      winner
      score
      Player1 {
        id
        age
        academy
        mobile
        email
        name
        video
        instagram
        youtube
        searchTag
        facebook
        createdAt
        updatedAt
      }
      Player2 {
        id
        age
        academy
        mobile
        email
        name
        video
        instagram
        youtube
        searchTag
        facebook
        createdAt
        updatedAt
      }
      categoryID
      url
      createdAt
      updatedAt
      matchPlayer1Id
      matchPlayer2Id
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      name
      organiserId
      status
      place
      description
      Categories {
        nextToken
      }
      startDate
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      name
      organiserId
      status
      place
      description
      Categories {
        nextToken
      }
      startDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      name
      organiserId
      status
      place
      description
      Categories {
        nextToken
      }
      startDate
      createdAt
      updatedAt
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      state
      eventID
      Matches {
        nextToken
      }
      Participants {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      state
      eventID
      Matches {
        nextToken
      }
      Participants {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      state
      eventID
      Matches {
        nextToken
      }
      Participants {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
