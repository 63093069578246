import { Button } from '@mui/material';
import { Component } from 'react';
import { Category } from '../../../API';


export default class CatageoryComponent extends Component<{ category: Category|null, toggleState: (id: string,name:string|null|undefined) => void,selectedId:String|null}> {
    constructor(props: any) {
        super(props);
        this.handleClick= this.handleClick.bind(this);
    }
    handleClick():void{
        if(this.props.category==null){
            return;
        }
        this.props.toggleState(this.props.category.id,this.props.category.name);
    }
    render() {
        return (
             <Button variant={this.getColour()} onClick={()=>this.handleClick()}>{this.props.category?.name}</Button>
        )
    }
    getColour(){
        if(this.props.selectedId!=null&&this.props.category!=null&&this.props.selectedId===this.props.category.id){
            return "contained";
        }
      
        return "text"
    }
}

