import { Button, Card, CardContent, Typography } from '@mui/material';
import { Component } from 'react';
import { Match, Event } from '../../../API';
import { MatchService } from '../match/MatchService';
import './../../../App.css';

export default class ViewMatchMobile extends Component<{ event: Event | null, selectedCategory: String|null }>{
  constructor(props: any) {
    super(props);
    this.handleWatch = this.handleWatch.bind(this);
  }
  private handleWatch(url: string | null | undefined) {
    if (url === null || url === undefined) {
      return;
    }
    window.open(url, "_blank")
  }
  render() {
    return this.getMobileMatch();
  }
  private getMobileMatch() {
    const matches = MatchService.getMatchFromEventInMemory(this.props.selectedCategory, this.props.event);
    if (matches === undefined) {
      return <div />;
    }
    return <div >
      <Typography sx={{ fontWeight: 'bold' }} align="left" variant="h3" className='event'>
        Match
      </Typography>
      {matches.map((row) => row != null && (this.getMatchCard(row)))}
    </div>

  }
  private getMatchCard(row: Match) {
    return (<div>
      <div className='decorative_bar' />
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} gutterBottom>
            {row?.round}
          </Typography>
          <Typography sx={{ fontSize: 14, color: '#f5822a' }}>
            {row?.Player1.name} ( {row?.Player1.academy} ) vs {row?.Player2.name} ( {row?.Player2.academy} )
          </Typography>
          <Typography >
            {row?.score}
          </Typography>
          <Typography variant="body2">
            {row?.winner}
          </Typography>
        </CardContent>
        <Button size="small" onClick={() => { this.handleWatch(row?.url) }}>Play </Button>
      </Card>
      <div />
    </div>
    );

  }
}
