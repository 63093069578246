import { Auth } from '@aws-amplify/auth'
import React, { Component } from 'react'

export default class Logout extends Component {
  render() {
    Auth.signOut();
    return (
      <div/>
    )
  }
}
