import { API } from "@aws-amplify/api";
import { CreateMatchInput, Event, Match, UpdateMatchInput } from "../../../API";
import { createMatch ,updateMatch} from "../../../graphql/mutations";


export class MatchService {

    public async saveMatch(match: CreateMatchInput) {
        await API.graphql({query:createMatch,variables:{input:match}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
    }
    public static  async updateMatch(match: Match) {
        const updMatch:UpdateMatchInput={id:match.id,score:match.score,winner:match.winner,url:match.url}
        await API.graphql({query:updateMatch,variables:{input:updMatch}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
    }

    public static getMatchFromEventInMemory(id:String|null,event:Event|null){
        const match = []
        if (event === undefined) {
            return undefined;
        }
        const categories = event?.Categories;
        if (!categories || categories.items == undefined) {
            return undefined;
        }
        for (let index = 0; index < categories.items.length; index++) {
            const category = categories.items[index];
            const matches = category?.Matches;
            if (!matches || matches.items == undefined) {
                return undefined;
            }
            if (id != null && category.id === id) {
                for (let index = 0; index < matches.items.length; index++) {
                    const mat = matches.items[index];
                    match.push(mat);
                }
            }

        }
        return match;

    }



}