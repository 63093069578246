import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Footer extends Component {
  render() {
    return (
      <div>
      <div>
All Right reserved to Gamma Games India
      </div>
      <Link to="/privacy">Privacy</Link>
      </div>
    )
  }
}
