import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Component } from 'react';
import { Event } from "../../../API";
import { MatchService } from '../match/MatchService';
import './../../../App.css';

export default class ViewMatchDesktop extends Component<{ event: Event | null,selectedCategory:String|null }> {
  constructor(props: any) {
    super(props);
    this.handleWatch = this.handleWatch.bind(this);
  }
  render() {
    return this.getMatch();
  }
  private getMatch(): JSX.Element {
    const matches = MatchService.getMatchFromEventInMemory(this.props.selectedCategory,this.props.event);
    if (matches === undefined) {
      return <div />;
    }

    return <div >
      <Typography sx={{ fontWeight: 'bold' }} align="left" variant="h3" className='event'>
        Match
      </Typography>
      <div className='table_match' >
        <div className='decorative_bar' />
        <TableContainer component={Paper}>
          <Table aria-label="customized table" className='hover'>
            <TableBody>
              {matches.map((row) => (
                <StyledTableRow key={row?.id} >
                  <StyledTableCell sx={{ fontWeight: 'bold' }} align="left" onClick={() => { this.handleWatch(row?.url) }}><PlayCircleOutlineIcon color="primary" /></StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'bold' }} align="left">{row?.round} </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'bold', color: '#f5822a' }} align="left">{row?.Player1.name} ( {row?.Player1.academy} ) vs {row?.Player2.name} ( {row?.Player2.academy} ) </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'bold' }} align="left">{row?.score} </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'bold' }} align="left">{row?.winner} </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  }
  handleWatch(url: string | null | undefined) {
    if (url === null || url === undefined) {
      return;
    }
    window.open(url, "_blank")
  }


}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));