/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAchievement = /* GraphQL */ `
  query GetAchievement($id: ID!) {
    getAchievement(id: $id) {
      id
      title
      year
      profileID
      createdAt
      updatedAt
    }
  }
`;
export const listAchievements = /* GraphQL */ `
  query ListAchievements(
    $filter: ModelAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAchievements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        year
        profileID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCoach = /* GraphQL */ `
  query GetCoach($id: ID!) {
    getCoach(id: $id) {
      id
      coachName
      from
      profileID
      createdAt
      updatedAt
    }
  }
`;
export const listCoaches = /* GraphQL */ `
  query ListCoaches(
    $filter: ModelCoachFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoaches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        coachName
        from
        profileID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      age
      academy
      mobile
      email
      name
      video
      instagram
      youtube
      Coaches {
        nextToken
      }
      Achievements {
        nextToken
      }
      searchTag
      facebook
      createdAt
      updatedAt
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        age
        academy
        mobile
        email
        name
        video
        instagram
        youtube
        searchTag
        facebook
        createdAt
        updatedAt
        Achievements {
          items{
          id
          title
          year
          }
          nextToken
        }
        Coaches {
          items{
          id
          coachName
          from
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getParticipants = /* GraphQL */ `
  query GetParticipants($id: ID!) {
    getParticipants(id: $id) {
      id
      Profile {
        id
        age
        academy
        mobile
        email
        name
        video
        instagram
        youtube
        searchTag
        facebook
        createdAt
        updatedAt
      }
      categoryID
      createdAt
      updatedAt
      participantsProfileId
    }
  }
`;
export const listParticipants = /* GraphQL */ `
  query ListParticipants(
    $filter: ModelParticipantsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParticipants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categoryID
        createdAt
        updatedAt
        participantsProfileId
      }
      nextToken
    }
  }
`;
export const getMatch = /* GraphQL */ `
  query GetMatch($id: ID!) {
    getMatch(id: $id) {
      id
      title
      round
      winner
      score
      Player1 {
        id
        age
        academy
        mobile
        email
        name
        video
        instagram
        youtube
        searchTag
        facebook
        createdAt
        updatedAt
      }
      Player2 {
        id
        age
        academy
        mobile
        email
        name
        video
        instagram
        youtube
        searchTag
        facebook
        createdAt
        updatedAt
      }
      categoryID
      url
      createdAt
      updatedAt
      matchPlayer1Id
      matchPlayer2Id
    }
  }
`;
export const listMatches = /* GraphQL */ `
  query ListMatches(
    $filter: ModelMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMatches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        round
        winner
        score
        categoryID
        url
        createdAt
        updatedAt
        matchPlayer1Id
        matchPlayer2Id
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      name
      organiserId
      status
      place
      description
      Categories {
        items{
          id
          name
          state
          Matches {
            items{
              id
              title
              round
              winner
              score
              url
              Player1 {
                id
                academy
                name
              }
              Player2 {
                id
                academy
                name
              }
              categoryID
            }
            nextToken
          }
        }
        nextToken
      }
      startDate
      createdAt
      updatedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        organiserId
        status
        place
        description
        startDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      state
      eventID
      Matches {
        items {
          id
          title
          round
          winner
          score
          url
          Player1{
            id
            name
            academy
          }
          Player2{
            id
            name
            academy
          }
        }
        nextToken
      }
      Participants {
        items{
          id
          categoryID
          Profile{
            id
            name
            academy
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        state
        eventID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
