import { Paper } from '@mui/material';
import Container from '@mui/material/Container';
import UpcomingEvents from './events/UpcomingEvents';
import ResponsivePlayer from './ResponsivePlayer';

const Home = () => {
  return (
    <div className="home">
      <Container maxWidth="xl" >
        <Paper elevation={3}>
          <ResponsivePlayer />
        </Paper>
      </Container>
      <UpcomingEvents />
    </div>
  )
}
export default Home