import { Button, Card, CardContent, Dialog, DialogTitle, TextField, Typography } from '@mui/material';
import { Component } from 'react';
import { Coach, CreateCoachInput, Profile } from '../../API';
import { ProflieService } from './ProfileService';

export default class CoachScreen extends Component<{ userProfile: Profile | undefined }, { open: boolean, caoch: CreateCoachInput }> {
  constructor(props: any) {
    super(props);
    this.state = { open: false, caoch: { profileID: " " } }
    this.openPopup = this.openPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateCoach = this.updateCoach.bind(this);


  }
 
  openPopup(evt: any) {
    this.setState({ open: true });
  }
  closePopup(evt: any) {
    this.setState({ open: false });

  }
  updateCoach(evt: any) {
    if (this.props.userProfile !== undefined) {
      const coach= this.state.caoch;
      coach.profileID=this.props.userProfile.id
      ProflieService.addCoach(coach);
    }
    
    this.setState({ open: false });
  }
  removeCoach(coach: Coach) {
    ProflieService.removeCoach(coach);
  }
  handleChange(evt: any) {
    evt.preventDefault();
    const value = evt.target.value;
    const name = evt.target.name;
    let tempProfile = this.state.caoch;
    this.setState({ caoch: { ...tempProfile, [name]: value } })

  }
  render() {
    return (
      <div className='child'>
      <div className='decorative_bar'  />
      {this.getUpdateCoachDialog()}
      {this.showCoaches()}
    </div>
    )
  }
  private showCoaches() {
    if (this.props.userProfile === undefined) {
      return <div />
    }
    const coaches = this.props.userProfile.Coaches
    if (coaches === undefined || coaches?.items === undefined) {
      return <div />
    }
    let coachArray = []
    for (let index = 0; index < coaches.items.length; index++) {
      const coach = coaches.items[index];
      if (coach != null) {
        coachArray.push(<div className='athlete_box'>
          <Card variant="outlined"  sx={{ width: 200,height:120 }}>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                {coach?.coachName}
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                {coach?.from}
              </Typography>
            </CardContent>
            <Button size="small" onClick={() => { this.removeCoach(coach) }}>Remove </Button>
          </Card>

        </div>)
      }
    }
    return coachArray;

  }

  private getUpdateCoachDialog() {
    return (
      <div>
        <br/>
        <Button size="small" variant="contained" color="primary" onClick={this.openPopup}>Add Coach</Button>
        <Dialog onClose={this.closePopup} open={this.state.open} sx={{
          '& .MuiTextField-root': {
            m: 1, width:'200px'
          },  pl: '5%', pt: '2%', pb: '2%', borderRadius: 2,
        }

        }>
          <DialogTitle>Coach Details</DialogTitle>

          <div>
            <div>
              <TextField
                required
                value={this.state.caoch.coachName}
                id="outlined-required"
                label="Coach"
                name="coachName"
                onChange={this.handleChange}
              />
            </div>
            <div>
              <TextField
                required
                value={this.state.caoch.from}
                id="outlined-required"
                label="Year From"
                name="from"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <Button size="small" onClick={this.updateCoach}>Done </Button>
          <Button size="small" onClick={this.closePopup}>Cancel</Button>
        </Dialog>
      </div>
    )
  }
}
