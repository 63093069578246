import { API } from '@aws-amplify/api';
import { CreateParticipantsInput } from '../../../API';
import { createParticipants } from '../../../graphql/mutations';
import { listParticipants } from '../../../graphql/queries';


export class ParticipantService{


    public async getParticipant(categoryId:string):Promise<any>{
            return await API.graphql({query: listParticipants,variables: {filter:{participantsCategoryId:{eq:categoryId}}}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
        
    }
    public removeParticipant(id:string){
        
    }
    public static async participate(participant:CreateParticipantsInput){
        await API.graphql({query: createParticipants,variables:{input:participant} , authMode: 'AMAZON_COGNITO_USER_POOLS'});
    }
}