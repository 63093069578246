export const findProfile = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        academy
        name
        video
        instagram
        youtube
        facebook
        createdAt
        updatedAt
        searchTag
      }
      nextToken
    }
  }
`;
export const getProfileView = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      age
      academy
      name
      video
      instagram
      youtube
      facebook
      Achievements {
        items{
        id
        title
        year
        }
        nextToken
      }
      Coaches {
        items{
        id
        coachName
        from
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const getEventAdmin = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      name
      organiserId
      status
      place
      description
      Categories {
        items{
          id
          name
          state
          Participants{
            items{
              Profile{
              id
              academy
              name
              }
            }
          }
          Matches {
            items{
              id
              title
              round
              winner
              score
              url
              Player1 {
                id
                academy
                name
              }
              Player2 {
                id
                academy
                name
              }
              categoryID
            }
            nextToken
          }
        }
        nextToken
      }
      startDate
      createdAt
      updatedAt
    }
  }
`;