import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useNavigate, useParams } from 'react-router-dom';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    
    return (
      <div>
      <Component
        navigate={navigate}
        {...props}
        />
        </div>
    );
  };
  
  return Wrapper;
};
export const withParams = (Component) => {
  const Wrapper = (props) => {
    const params = useParams();
    
    return (
      <div>
      <Component
        params={params}
        {...props}
        />
        </div>
    );
  };
  
  return Wrapper;
};

export const withAuthentication = (Component) => {
  const Wrapper = (props) => {
    const params = useParams();
    
    return (
      <div>
      <Component
        params={params}
        {...props}
        />
        </div>
    );

  };
  
  return withAuthenticator(Wrapper);
};
export const navigateWithAuthentication = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    
    return (
      <div>
      <Component
       navigate={navigate}
        {...props}
        />
        </div>
    );

  };
  
  return withAuthenticator(Wrapper);
};